export const FirebaseConfig = {
	"projectId": "wayhome-technologies-pvt-ltd",
	"appId": "1:932933543706:web:4199de0dda90502e256ea3",
	"databaseURL": "https://wayhome-technologies-pvt-ltd-default-rtdb.firebaseio.com",
	"storageBucket": "wayhome-technologies-pvt-ltd.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyD-XYU9auF7gvI-Hq2ostmiLblwwo0d1Ig",
	"authDomain": "wayhome-technologies-pvt-ltd.firebaseapp.com",
	"messagingSenderId": "932933543706",
	"measurementId": "G-6VJYXPZLM8"
};